import { useQuery } from '@tanstack/react-query';

import type { Community } from '@zealy/queries';
import { communityKeys, getCommunity, getCommunityV2 } from '@zealy/queries';
import { getSubdomain, isValidSubdomain } from '@zealy/utils';

import { useCommunitiesFlags } from '#context/FeatureFlags';

/**
 * Queries a community by its subdomain
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useCommunityV2('my-community');
 */
export const useCommunityV2 = (subdomain?: string, initialData?: Community, enabled = true) => {
  const domain = subdomain || getSubdomain();
  const { ff_use_get_community_api_v2 } = useCommunitiesFlags(['ff_use_get_community_api_v2']);

  return useQuery({
    queryKey: communityKeys.community(domain),
    queryFn: async () =>
      ff_use_get_community_api_v2?.enabled ? getCommunityV2(domain) : getCommunity(domain),
    initialData,
    enabled: isValidSubdomain(domain) && enabled,
    retry: 1,
  });
};
